(function(wnd){
    function NewsletterForm(form, params) {
        try {
            if (!form) {
                throw 'Form is required';
            }

            this.form = form;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch(e) {
            console.error(e);
        }
    }

    NewsletterForm.prototype = {
        sendedForm: false,
        form: undefined,
        button: undefined,
        notyf: undefined,
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.button = this.form.find('[data-purpose="send-button"]');
            this.notyf = new Notyf();
        },
        addDOMEvents: function() {
            var _self = this;
            if (this.button) {
                this.button.click(function(){
                    _self.sendForm();
                });
            }
        },
        setParams: function(params) {

        },
        sendForm: function() {
            var _self = this;
            if (!this.sendedForm) {
                this.sendedForm = true;

                $.ajax({
                    url:ajax_controller+'newsletterSubscribe',
                    dataType:'json',
                    type:'post',
                    data: _self.form.serialize(),
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {

                        if (typeof gtag === 'function') {
                            var nameInput = _self.form.find('input[name="name"]');
                            var name = nameInput.length > 0 && (nameInput.val()).trim() !== '' ? nameInput.val() : 'Anonymous'    

                            gtag('event', 'Hírlevél', {
                                'event_category': 'Lead',
                                'event_action': (response.success ? 'Sikeres' : 'Sikertelen')+' feliratkozás',
                                'event_label': name
                            });
                        }
                        
                        if (response.success) {

                            _self.form.find('input').each(function(key, item){
                                $(item).val('');
                            });

                            if (response.msg) {
                                _self.notyf.confirm(response.msg);
                            }
                        } else {
                            if (response.errors) {
                                $.each(response.errors, function(key, value){
                                    if (value.trim() !== '') {
                                        _self.notyf.alert(value);
                                    }

                                    _self.form.find('[name="'+key+'"]').addClass('has-error');
                                });

                                
                            }
                        }
                    },
                    complete: function() {
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 1000);
                    }
                });
            }
        }
    }


    wnd.NewsletterForm = NewsletterForm;
})(window);
