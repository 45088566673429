(function(window){
    function SurgerySection(parent, params) {
        if (params) {
            this.setParams(params);
        }

        this.parent = parent;

        this.init();
    }

    SurgerySection.prototype = {
        parent:undefined,
        content: undefined,
        city: undefined,
        address: undefined,
        phone: undefined,
        office_hour: [],
        mapContent: undefined,
        location: {},
        persons:[],
        index: 0,
        map: undefined,
        init: function() {
            this.createElements();
            this.createContent();
            this.addDOMEvents();
        },
        addDOMEvents: function() {
            var _self = this;

            $(document).on('pwcms.googlemapsloaded', function(){
                _self.loadMap();
            });
        },
        loadMap: function(){
            var _self = this;
            var id = this.mapContent.attr('id');
            var options = this.parent.getMapOptions();
            
             var center = [this.location.lng, this.location.lat];
             options.center = center;


            mapboxgl.accessToken = 'pk.eyJ1IjoiZXBkZW50IiwiYSI6ImNqemIxYTM0NDAzMXkzZGsyZXYyM2JnaGUifQ.yTqTmSrPHHEeXlNJpHzA2A';
            this.map =  new mapboxgl.Map({
                container: id,
                style: 'mapbox://styles/epdent/cjzb1dstu05jp1cpc6e4j16z0',
                zoom: 15,
                center: center
            });

            this.map.scrollZoom.disable();

            var el = document.createElement('div');
            el.className = 'marker';
            el.innerHTML = '<div class="info-box-container" title="Ép-dent rendelő">Ép-Dent rendelő</div>';
            el.style.top = '-70px';
            el.style.left = '-10px';
        
            new mapboxgl.Marker(el)
                .setLngLat(center)
                .addTo(this.map);



        },
        createElements: function() {
            this.content = $('<div>', {
                class:'surgery-content '+(this.getIndex() % 2 > 0 ? 'left-map' : '')
            });

            this.mapContent = $('<div>', {
                class: 'map-outer',
                id: 'contact-map-'+this.getIndex()
            });

            this.content.append(this.mapContent);
        },
        createContent: function() {
            var content = $('<div>', {
                class:'text-content'
            });

            var inner = $('<div>', {
                class:'inner-content'
            });

            var phone = this.getPhone();
            phone = phone.replace(/ /g,'');

            inner.append('<h2>'+this.getCity()+'</h2>');
            inner.append('<div class="contact-row"><address>'+this.getAddress()+'</address><span class="divider">|</span><a href="tel:'+phone+'">'+this.getPhone()+'</a></div>');

            if (this.office_hour.length > 0) {
                var hourContent = $('<div>', {
                    'class': 'office-hours',
                    'html': '<h3>'+this.parent.getText('office_hour_title')+':</h3>'
                });

                for (var i = 0; i < this.office_hour.length; i++) {
                    var item = this.office_hour[i];
                    var row = $('<div>', {
                        class:'row'
                    });
                    
                    row.append('<div class="day">'+item.day+':</div>');
                    row.append('<div class="time">'+item.time+'</div>');
                    
                    hourContent.append(row);
                }

                inner.append(hourContent);
            }

            if (this.persons.length > 0) {
                var personsContent = $('<div>', {
                    'class':'persons-outer'
                });

                for (var i = 0; i < this.persons.length; i++) {
                    personsContent.append(this.getPersonBox(this.persons[i]));
                }

                inner.append(personsContent);
            }

            content.append(inner);
            this.content.append(content);
        },
        getPersonBox: function(person) {
            var content = $('<div>', {
                class:'person-box'
            });

            content.append('<div class="image-content"><img src="'+base_url+'assets/img/person/'+person.img+'" alt="'+person.name+'"/></div>');
            
            var textContent = $('<div>', {
                class:'text-content'
            });

            textContent.append('<div class="name">'+person.name+'</div>');
            textContent.append('<div class="position">'+person.position+'</div>');

            content.append(textContent);
            return content;
        },
        setParams: function(params) {
            if (params.city) this.setCity(params.city);
            if (params.address) this.setAddress(params.address);
            if (params.phone) this.setPhone(params.phone);
            if (params.office_hour) this.setOfficeHour(params.office_hour);
            if (params.index) this.setIndex(params.index);
            if (params.persons) this.setPersons(params.persons);
            if (params.location) this.setLocation(params.location);
        },
        setCity: function(city) {
            this.city = city;
        },
        setAddress: function(address) {
            this.address = address;
        },
        setPhone: function(phone) {
            this.phone = phone;
        },
        setOfficeHour: function(office_hour) {
            this.office_hour = office_hour;
        },
        setIndex: function(index) {
            this.index = index;
        },
        setPersons: function(persons) {
            this.persons = persons;
        },
        setLocation: function(location) {
            this.location = location;
        },
        getCity: function() {
            return this.city;
        },
        getAddress: function() {
            return this.address;
        },
        getPhone: function() {
            return this.phone;
        },
        getOfficeHour: function() {
            return this.office_hour;
        },
        getIndex: function() {
            return this.index;
        },
        getPersons: function() {
            return this.persons;
        }
    }

    function ContactSurgery(container, params) {
        try {
            if (!container) {
                throw 'Container is required!';
            }

            this.container = container;
            
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (e) {
            console.error(e);
        }
    }

    ContactSurgery.prototype = {
        container: undefined,
        surgeries: {},
        texts: {},
        init: function() {
            this.createSelectorContent();
            this.writeSurgeries();

            this.addDOMEvents();
        },
        addDOMEvents: function() {
            var _self = this;

            this.container.find('[data-purpose="city-tag"]').click(function(){
                var city = $(this).data('city');
                
                var item = _self.container.find('.city-container-outer[data-city="'+city+'"]');
                
                if (item.length > 0) {
                    $('html, body').animate({scrollTop: item.offset().top}, 400);
                }
            });
        },
        setParams: function(params) {
            if (params.surgeries) this.setSurgeries(params.surgeries);
            if (params.texts) this.setTexts(params.texts);
        },
        setSurgeries: function(surgeries) {
            this.surgeries = surgeries;
        },
        setTexts: function(texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        getSurgeries: function() {
            return this.surgeries;
        },
        getText: function(key) {
            if (key) {
                return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
            }

            return this.texts;
        },
        createSelectorContent: function() {
            var keys = Object.keys(this.surgeries);
            if (keys.length > 0) {
                var content = $('<div>', {
                    class:'site-section city-selector',
                });

                var inner = $('<div>', {
                    class:'content-width thin-content',
                    html: '<h2>'+this.getText('surgery_selector_title')+'</h2><div class="lead">'+this.getText('surgery_selector_lead')+'</div>'
                });

                var tagsOuter = $('<div>', {
                    class:'tags-outer'
                });

                for (var i = 0; i < keys.length; i++) {
                    tagsOuter.append('<a href="javascript:void(0)" class="tag" data-purpose="city-tag" data-city="'+keys[i]+'">'+this.surgeries[keys[i]].name+'</a>')
                }
                
                inner.append(tagsOuter);

                content.append(inner);
                

                this.container.append(content);
            }
        },
        writeSurgeries: function() {
            var keys = Object.keys(this.surgeries);
            if (keys.length > 0) {
                var index = 0;
                for (var i = 0; i < keys.length; i++) {
                    var city = typeof this.surgeries[keys[i]] !== 'undefined' ? this.surgeries[keys[i]] : undefined;
                    if (city && typeof city.surgeries !== 'Array') {
                        var cityContainer = $('<div>', {
                            class: 'city-container-outer',
                            'data-city': keys[i],
                            id: keys[i]
                        });

                        cityContainer.append('<div class="city-header"><h3>'+city.name+'</h3></div>');
                        var surgeryContainer = $('<div>', {
                            class: 'surgeries-outer'
                        });
                        for (var j = 0; j < city.surgeries.length; j++) {
                            var data = city.surgeries[j];
                            data.city = city.name;
                            data.index = index;
                            var obj = new SurgerySection(this, data);

                            surgeryContainer.append(obj.content);
                            index++;
                        }
                        cityContainer.append(surgeryContainer);
                        this.container.append(cityContainer);
                    }
                }
            }
        },
        getMapOptions: function (){
            return {
                zoom: 15,
                scrollwheel: false,
                mapTypeControl: false,
                scaleControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                zoomControl: false,
                styles: getMapStyle()
            };
        }
    };

    window.SurgeryMaps = ContactSurgery;
})(window);
